import { httpGet } from "@/request/http";

let path = "businesses";
const url = process.env.VUE_APP_API_URL;

export default {
  getPart() {
    return httpGet(`${url}/businesspart`);
  },
  getImage() {
    return httpGet(`${url}/${path}/member-web/image`, null, {
      responseType: "blob",
    });
  },
};
