<template>
  <component :is="componentId" v-if="componentId"></component>
</template>

<script>
import RegisterEnterNumber from "@/components/ushow/RegisterEnterNumber";
import RegisterCheckVerify from "@/components/ushow/RegisterCheckVerify";
import RegisterSetPassword from "@/components/ushow/RegisterSetPassword";
import FillOutProfile from "@/components/ushow/FillOutProfile";
import ForgetPasswordEnterNumber from "@/components/ushow/ForgetPasswordEnterNumber";
import ForgetPasswordCheckVerify from "@/components/ushow/ForgetPasswordCheckVerify";
import ForgetPasswordSetPassword from "@/components/ushow/ForgetPasswordSetPassword";

export default {
  data() {
    return {};
  },
  components: {
    RegisterEnterNumber,
    RegisterCheckVerify,
    RegisterSetPassword,
    FillOutProfile,
    ForgetPasswordEnterNumber,
    ForgetPasswordCheckVerify,
    ForgetPasswordSetPassword,
  },
  computed: {
    componentId() {
      let name = this.$route.name.replace("Shop", "");
      return name;
    },
  },
};
</script>
