<template>
  <form
    style="
      width: 100%;
      max-width: 768px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      background-color: #f7f6f6;
    "
    @submit.prevent="save"
  >
    <div
      style="
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      "
    >
      <div style="font-size: 24px; font-weight: 500; padding: 20px 0">
        會員資料
      </div>
      <label for="photo" style="position: relative">
        <div
          style="
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 64px;
            border-radius: 32px;
            background-color: rgb(62, 91, 169);
            color: rgb(255, 255, 255);
            margin-bottom: 30px;
            font-size: 36px;
            overflow: hidden;
          "
        >
          <div v-if="!memberPhotoURL" style="margin-bottom: 6px">
            {{ firstWord }}
          </div>
          <img
            v-else
            :src="memberPhotoURL"
            :onerror="resetPhoto"
            style="
              position: absolute;
              object-fit: cover;
              width: 100%;
              height: 100%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
            alt=""
          />
        </div>
        <div
          style="
            position: absolute;
            top: 41px;
            right: 0;
            width: 24px;
            height: 24px;
            font-size: 15px;
            color: white;
            background-color: rgba(200, 200, 200, 0.7);
            border-radius: 12px;
          "
        >
          <font-awesome-icon :icon="['fas', 'camera']" />
        </div>
      </label>
      <input
        type="file"
        id="photo"
        name="photo"
        accept="image/png, image/jpeg"
        hidden
        @change="changePhoto"
      />
      <div
        style="
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 30px;
          position: absolute;
          left: 20px;
          color: black;
          font-size: 24px;
        "
        @click="backToHome"
      >
        <font-awesome-icon :icon="['fas', 'angle-left']" />
      </div>
    </div>
    <div
      v-if="!isEdit"
      style="
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 54px;
        margin-bottom: 10px;
        padding: 0 20px;
        background-color: white;
      "
      @click="changePassword"
    >
      <div style="color: #3e5ba9">變更密碼</div>
      <div style="color: #828282; font-size: 20px">
        <font-awesome-icon :icon="['fas', 'angle-right']" />
      </div>
    </div>
    <div
      v-else
      style="
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 64px;
        padding: 0 20px;
        background-color: white;
      "
    >
      <div style="color: #3e5ba9; font-size: 20px">編輯資料</div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: white;
      "
    >
      <div style="box-sizing: border-box; padding: 0 20px; width: 100%">
        <div
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 50px;
          "
          :style="[
            isEdit
              ? 'border-bottom: 1px solid white'
              : 'border-bottom: 1px solid #D9D9D9',
          ]"
        >
          <label class="standard-label label-blue" for="name">姓名</label>
          <div v-if="!isEdit">{{ info.Name }}</div>
          <input
            v-else
            class="standard-input"
            type="text"
            name="name"
            v-model="info.Name"
            required
          />
        </div>
      </div>
      <div style="box-sizing: border-box; padding: 0 20px; width: 100%">
        <div
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 50px;
          "
          :style="[
            isEdit
              ? 'border-bottom: 1px solid white'
              : 'border-bottom: 1px solid #D9D9D9',
          ]"
        >
          <label class="standard-label label-blue" for="gender">性別</label>
          <div v-if="!isEdit">{{ info.Gender }}</div>
          <div v-else class="join-options gender-options">
            <div
              class="join-option gender-option"
              data-option="男性"
              @click="changeGender('男性', $event)"
            >
              <div class="selected-circle">
                <font-awesome-icon icon="fa-solid fa-check" />
              </div>
              男性
            </div>
            <div
              class="join-option gender-option"
              data-option="女性"
              @click="changeGender('女性', $event)"
            >
              <div class="selected-circle">
                <font-awesome-icon icon="fa-solid fa-check" />
              </div>
              女性
            </div>
            <div
              class="join-option gender-option"
              data-option="其他"
              @click="changeGender('其他', $event)"
            >
              <div class="selected-circle">
                <font-awesome-icon icon="fa-solid fa-check" />
              </div>
              其他
            </div>
          </div>
        </div>
      </div>

      <div style="box-sizing: border-box; padding: 0 20px; width: 100%">
        <div
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 50px;
          "
          :style="[
            isEdit
              ? 'border-bottom: 1px solid white'
              : 'border-bottom: 1px solid #D9D9D9',
          ]"
        >
          <label class="standard-label label-blue" for="birth">生日</label>
          <div v-if="!isEdit || info.Birth">{{ info.Birth }}</div>
          <div v-else class="birthdate-inputs">
            <select
              class="year-input"
              name="year"
              id="year"
              v-model="birthYear"
              required
            >
              <option
                class="birthdate-option"
                v-for="(year, index) in years"
                :key="index"
                :value="year"
              >
                {{ year }}
              </option>
            </select>
            <label class="birthdate-label" for="year">年</label>
            <select
              class="month-input"
              name="month"
              id="month"
              v-model="birthMonth"
              required
            >
              <option
                class="birthdate-option"
                v-for="(month, index) in months"
                :key="index"
                :value="month"
              >
                {{ month }}
              </option>
            </select>
            <label class="birthdate-label" for="month">月</label>
            <select
              class="day-input"
              name="day"
              id="day"
              v-model="birthDay"
              required
            >
              <option
                class="birthdate-option"
                v-for="(day, index) in days"
                :key="index"
                :value="day"
              >
                {{ day }}
              </option>
            </select>
            <label class="birthdate-label" for="day">日</label>
          </div>
        </div>
      </div>
      <div style="box-sizing: border-box; padding: 0 20px; width: 100%">
        <div
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 50px;
          "
          :style="[
            isEdit
              ? 'border-bottom: 1px solid white'
              : 'border-bottom: 1px solid #D9D9D9',
          ]"
        >
          <label class="standard-label label-blue" for="phone">電話</label>
          <div>{{ info.Cellphone }}</div>
        </div>
      </div>
      <div style="box-sizing: border-box; padding: 0 20px; width: 100%">
        <div
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 50px;
          "
          :style="[
            isEdit
              ? 'border-bottom: 1px solid white'
              : 'border-bottom: 1px solid #D9D9D9',
          ]"
        >
          <label class="standard-label label-blue" for="email">Email</label>
          <div v-if="!isEdit">{{ info.Email }}</div>
          <input
            v-else
            class="standard-input"
            type="text"
            name="email"
            v-model="info.Email"
          />
        </div>
      </div>
      <div style="box-sizing: border-box; padding: 0 20px; width: 100%">
        <div
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 50px;
          "
          :style="[
            isEdit
              ? 'border-bottom: 1px solid white'
              : 'border-bottom: 1px solid #D9D9D9',
          ]"
        >
          <label class="standard-label label-blue" for="country">國家</label>
          <div v-if="!isEdit">{{ countryText }}</div>
          <div v-else class="join-options country-options">
            <div
              class="join-option country-option"
              data-option="台灣"
              @click="changeCountry('台灣', $event)"
            >
              <div class="selected-circle">
                <font-awesome-icon icon="fa-solid fa-check" />
              </div>
              台灣
            </div>
            <div
              class="join-option country-option"
              data-option="其他"
              @click="changeCountry('其他', $event)"
            >
              <div class="selected-circle">
                <font-awesome-icon icon="fa-solid fa-check" />
              </div>
              其他
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="info.country == 1"
        style="box-sizing: border-box; padding: 0 20px; width: 100%"
      >
        <div
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 50px;
          "
          :style="[
            isEdit
              ? 'border-bottom: 1px solid white'
              : 'border-bottom: 1px solid #D9D9D9',
          ]"
        >
          <label class="standard-label label-blue" for="elseCountry"
            >國名</label
          >
          <div v-if="!isEdit">{{ info.elseCountry }}</div>
          <input
            v-else
            class="standard-input"
            type="text"
            name="elseCountry"
            v-model="info.elseCountry"
          />
          <div></div>
        </div>
      </div>
      <div style="box-sizing: border-box; padding: 0 20px; width: 100%">
        <div
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 50px;
          "
        >
          <label class="standard-label label-blue" for="address">地址</label>
          <div v-if="!isEdit">{{ info.Address }}</div>
          <input
            v-else
            class="standard-input"
            type="text"
            name="address"
            v-model="info.Address"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 30px 0 20px 0;
        "
      >
        <button
          v-if="!isEdit"
          class="submit-btn"
          type="button"
          style="text-decoration: none; padding-bottom: 3px; font-weight: 500"
          @click="edit"
        >
          編輯
        </button>
        <button
          v-else
          class="submit-btn"
          type="submit"
          style="text-decoration: none; padding-bottom: 3px; font-weight: 500"
        >
          確定
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      isEdit: false,
      info: {},
      firstWord: "",
      countryText: "",
      years: [],
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      days: [],
      birthYear: "",
      birthMonth: "",
      birthDay: "",
      memberPhotoURL: "",
    };
  },
  watch: {
    birthMonth(value) {
      this.setDays(value);
    },
  },
  methods: {
    resetPhoto() {
      this.memberPhotoURL = "";
    },
    getInfo() {
      if (window.localStorage["imageURL"]) {
        this.memberPhotoURL = `${localStorage.getItem(
          "imageURL"
        )}?_=${new Date()}`;
      }
      this.memberPhotoURL = localStorage.getItem("imageURL");
      this.$api.members.getInfo().then((data) => {
        this.info = data;
        if (!this.info.Birth) {
          const nowYear = new Date().getFullYear();
          for (let i = 0; i < 100; i++) {
            this.years.push(`${nowYear - i}`);
          }
          this.setDays("1");
        }
        this.firstWord = this.info.Name.substr(0, 1);
        if (this.info.Country == "台灣") {
          this.countryText = "台灣";
        } else if (this.info.Country == "其他") {
          this.countryText = "其他";
        }
      });
    },
    changePhoto(e) {
      if (e.target.files[0].size > 1024000) {
        this.$swal.fire({
          icon: "alert",
          title: "檔案大小不可超過1MB !",
          showConfirmButton: true,
        });
        return;
      }
      let file = e.target.files[0];

      let formData = new FormData();
      formData.append("file", file);
      this.$api.members
        .updateImage(formData)
        .then(() => {
          this.$swal.fire({
            icon: "success",
            title: "照片更新成功",
            showConfirmButton: true,
          });
          this.getInfo();
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.data.Message,
            showConfirmButton: true,
          });
        });
    },
    changePassword() {
      this.$router.push({
        name: "ChangePassword",
      });
    },
    edit() {
      this.isEdit = true;
      this.$nextTick(() => {
        let genders = document.querySelectorAll(".gender-option");
        genders.forEach((g) => {
          if (g.getAttribute("data-option") == this.info.Gender) {
            g.classList.add("selected");
          }
        });
        let countrys = document.querySelectorAll(".country-option");
        countrys.forEach((g) => {
          if (g.getAttribute("data-option") == this.info.Country) {
            g.classList.add("selected");
          }
        });
      });
    },
    save() {
      if (!this.info.Birth) {
        this.info.Birth = `${this.birthYear}-${this.birthMonth.padStart(
          2,
          "0"
        )}-${this.birthDay.padStart(2, "0")}`;
      }
      this.$api.members
        .saveInfo(this.info)
        .then(() => {
          this.$swal.fire({
            icon: "success",
            title: "資料編輯成功 !",
            showConfirmButton: true,
          });
          this.getInfo();
          this.isEdit = false;
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.data.Message,
            showConfirmButton: true,
          });
        });
    },
    changeGender(gender, e) {
      if (this.info.Gender == gender) {
        return;
      }
      this.info.Gender = gender;
      let genders = document.querySelectorAll(".gender-option");
      genders.forEach((g) => {
        if (g.classList.contains("invalid")) {
          g.classList.remove("invalid");
        }
        g.classList.remove("selected");
      });
      e.target.classList.add("selected");
    },
    changeCountry(country, e) {
      if (this.info.Country == country) {
        e.target.classList.remove("selected");
        this.info.Country = null;
        return;
      }
      this.info.Country = country;
      let countrys = document.querySelectorAll(".country-option");
      countrys.forEach((c) => {
        if (c.classList.contains("invalid")) {
          c.classList.remove("invalid");
        }
        c.classList.remove("selected");
      });
      e.target.classList.add("selected");
    },
    backToHome() {
      this.$router.push({
        name: "Home",
      });
    },
    setDays(month) {
      this.days = [];
      if (
        month == "1" ||
        month == "3" ||
        month == "5" ||
        month == "7" ||
        month == "8" ||
        month == "10" ||
        month == "12"
      ) {
        for (let i = 0; i < 31; i++) {
          this.days.push(`${i + 1}`);
        }
      } else if (month == "2") {
        for (let i = 0; i < 29; i++) {
          this.days.push(`${i + 1}`);
        }
      } else {
        for (let i = 0; i < 30; i++) {
          this.days.push(`${i + 1}`);
        }
      }
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>
