<template>
  <div style="display: flex; justify-content: center; width: 100%">
    <loading v-model:active="isLoading"></loading>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  beforeCreate() {
    // console.log(localStorage.getItem("businessId"));
    // if (!localStorage.token) {
    //   console.log(this.$route.params.id);
    //   if (this.$route.params.id) {
    //     this.$router.push({
    //       name: "ShopLogin",
    //       params: {
    //         id: this.$route.params.id,
    //       },
    //     });
    //   } else {
    //     this.$router.push({ name: "Login" });
    //   }
    // }
  },
  beforeUnmount() {
    localStorage.removeItem("shopId");
  },
};
</script>

<style lang="scss">
@import "./assets/all";
#app {
  font-family: "PingFang", "Noto Sans TC", "Microsoft JhengHei", Avenir,
    Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
