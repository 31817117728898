<template>
  <div id="third-step">
    <div style="padding: 50px 0 10px 0; font-size: 24px; font-weight: 500">
      重設密碼
    </div>
    <div>
      <div class="set-password-input">
        <label class="set-pwd-label label-blue" for="setPwd">輸入新密碼</label>
        <input
          id="setPwd"
          class="set-pwd-input"
          type="password"
          v-model="password"
          placeholder="密碼"
          required
        />
        <div class="set-pwd-switch" @click="pwdSwitcher('setPwd')">
          <font-awesome-icon :icon="['fas', 'eye']" />
        </div>
      </div>
      <div class="set-password-input">
        <label class="set-pwd-label label-blue" for="repeatPwd"
          >確認新密碼</label
        >
        <input
          id="repeatPwd"
          class="set-pwd-input"
          type="password"
          v-model="repeatPassword"
          placeholder="密碼"
          required
        />
        <div class="set-pwd-switch" @click="pwdSwitcher('repeatPwd')">
          <font-awesome-icon :icon="['fas', 'eye']" />
        </div>
      </div>
    </div>

    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0 20px 0;
      "
    >
      <button
        class="submit-btn"
        style="text-decoration: none; padding-bottom: 3px; font-weight: 500"
        type="button"
        @click="checkPassword"
      >
        確定
      </button>
    </div>
    <div style="color: #3e5ba9; margin-top: -5px">
      <span style="cursor: pointer" @click="backToLogin">取消</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phoneNumber: "",
      verifyCode: "",
      password: "",
      repeatPassword: "",
      isShowResetPasswordSuccessModal: false,
    };
  },
  methods: {
    checkPassword() {
      if (this.password != this.repeatPassword) {
        this.$swal.fire({
          icon: "error",
          title: "輸入密碼不一致",
          showConfirmButton: true,
        });
        return;
      }
      this.$api.members
        .forgetPwd({
          Cellphone: this.phoneNumber,
          VerifyCode: this.verifyCode,
          Password: this.$func.crypto.encrypt(this.password),
        })
        .then(() => {
          this.$swal
            .fire({
              icon: "success",
              title: "密碼變更成功，請重新登入 !",
              showConfirmButton: true,
            })
            .then((isConfirmed) => {
              if (isConfirmed) {
                this.backToLogin();
              }
            });
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.data.Message,
            showConfirmButton: true,
          });
        });
    },
    pwdSwitcher(id) {
      const pwdInput = document.getElementById(id);
      if (pwdInput.type == "password") {
        pwdInput.type = "text";
      } else {
        pwdInput.type = "password";
      }
    },
    backToLogin() {
      this.$router.push({
        name: "Login",
      });
    },
  },
  mounted() {
    this.phoneNumber = localStorage.getItem("phoneNumber");
    this.verifyCode = localStorage.getItem("verifyCode");
  },
};
</script>
