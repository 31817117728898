<template>
  <div
    style="
      width: 100%;
      min-height: 100vh;
      max-width: 768px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      background-color: #f7f6f6;
    "
  >
    <div
      class="store-background"
      :style="{ backgroundImage: `url(${store.MemberBackgroundImageURL})` }"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-bottom: 30px;
        "
      >
        <div
          style="
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: 100;
            margin-left: 20px;
            padding-top: 5px;
          "
          @click="backToHome"
        >
          <div style="font-size: 24px">
            <font-awesome-icon :icon="['fas', 'house']" />
          </div>
          <div style="font-size: 12px">首頁</div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <div
            style="
              position: relative;
              width: 72px;
              height: 72px;
              border-radius: 36px;
              overflow: hidden;
            "
          >
            <img
              v-if="store.MemberLogoURL"
              :src="store.MemberLogoURL"
              style="
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              "
              alt=""
            />
            <div
              v-else
              style="width: 100%; height: 100%; background-color: white"
            ></div>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 3px 0 0 5px;
            "
          >
            <div>{{ store.MemberStoreName }}</div>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                opacity: 0.7;
                margin: 3px 0 0 3px;
              "
              @click="checkInfo"
            >
              <font-awesome-icon :icon="['fas', 'circle-info']" />
            </div>
          </div>
        </div>
        <div
          v-if="isMember"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 100;
            margin-right: 20px;
            padding-top: 5px;
          "
          @click="openBarcodeModal"
        >
          <div style="font-size: 24px">
            <font-awesome-icon :icon="['fas', 'barcode']" />
          </div>
          <div style="font-size: 12px">會員條碼</div>
        </div>
        <div v-else style="width: 48px; height: 48px; margin-right: 20px"></div>
      </div>
      <div
        v-if="isMember"
        style="
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          bottom: -32px;
          width: 350px;
          height: 64px;
          border-radius: 10px;
          background-color: white;
          color: black;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        "
      >
        <div
          id="myHistory"
          class="page-tags"
          style="
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 85px;
            height: 56px;
            font-size: 14px;
          "
          @click="clickTag"
        >
          <div style="position: relative; z-index: 10">
            <div style="margin-top: -5px; font-size: 24px; font-weight: 500">
              <font-awesome-icon :icon="['far', 'credit-card']" />
            </div>
            <div style="margin-top: -5px">消費紀錄</div>
          </div>
        </div>
        <div style="width: 1px; height: 44px; background-color: #d9d9d9"></div>
        <div
          id="myGoods"
          class="page-tags"
          style="
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 85px;
            height: 56px;
            font-size: 14px;
          "
          @click="clickTag"
        >
          <div style="position: relative; z-index: 10">
            <div style="margin-top: -5px; font-size: 22px; font-weight: 500">
              {{ member.CustomerStockQty }}
            </div>
            <div style="margin-top: -3px">我的商品</div>
          </div>
        </div>
        <div style="width: 1px; height: 44px; background-color: #d9d9d9"></div>
        <div
          id="myDeposit"
          class="page-tags"
          style="
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 85px;
            height: 56px;
            font-size: 14px;
          "
          @click="clickTag"
        >
          <div style="position: relative; z-index: 10">
            <div style="margin-top: -5px; font-size: 22px; font-weight: 500">
              {{ member.Deposit }}
            </div>
            <div style="margin-top: -3px">儲值金</div>
          </div>
        </div>
        <div style="width: 1px; height: 44px; background-color: #d9d9d9"></div>
        <div
          id="myPoint"
          class="page-tags"
          style="
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 85px;
            height: 56px;
            font-size: 14px;
          "
          @click="clickTag"
        >
          <div style="position: relative; z-index: 10">
            <div style="margin-top: -5px; font-size: 22px; font-weight: 500">
              {{ member.Integral }}
            </div>
            <div style="margin-top: -3px">點數</div>
          </div>
        </div>
      </div>
      <div v-else class="join-member-btn" @click="joinMember">
        <div class="join-member-content">
          <div class="join-member-icon">
            <font-awesome-icon :icon="['fas', 'user-plus']" />
          </div>
          <div class="join-member-text">加入會員</div>
        </div>
      </div>
    </div>
    <div
      style="
        box-sizing: border-box;
        width: 100%;
        margin-top: 200px;
        margin-bottom: 80px;
      "
    >
      <component :is="componentId" v-if="componentId" :item="store"></component>
    </div>
  </div>
  <MemberBarcode
    :isShowBarcodeModal="isShowBarcodeModal"
    :customerNumber="member.CustomerNumber"
    @closeBarcodeModal="closeBarcodeModal"
  ></MemberBarcode>
</template>

<script>
import ShopIntroduce from "@/components/shop/ShopIntroduce";
import MyHistory from "@/components/shop/MyHistory";
import MyGoods from "@/components/shop/MyGoods";
import MyDeposit from "@/components/shop/MyDeposit";
import MyPoint from "@/components/shop/MyPoint";
import MemberBarcode from "@/components/shop/MemberBarcode";

export default {
  data() {
    return {
      store: {},
      member: {},
      customerNumber: "",
      componentId: "",
      isShowBarcodeModal: false,
      isMember: false,
    };
  },
  components: {
    ShopIntroduce,
    MyHistory,
    MyGoods,
    MyDeposit,
    MyPoint,
    MemberBarcode,
  },
  methods: {
    getStore() {
      const vm = this;
      this.$api.stores.getStore(this.$route.params.id).then((data) => {
        vm.store = data;
        vm.$api.members.getMember(vm.$route.params.id).then((item) => {
          vm.member = item;
          vm.isMember = true;
        });
      });
    },
    backToHome() {
      this.$router.push({
        name: "Home",
      });
    },
    checkInfo() {
      return;
      const selectedTag = document.querySelector(".selected-tag");
      const pageTags = document.querySelectorAll(".page-tags");
      if (selectedTag) {
        selectedTag.remove();
        pageTags.forEach((t) => {
          t.classList.remove("color-white");
        });
      }
      this.componentId = "ShopIntroduce";
    },
    clickTag(e) {
      return;
      const div = e.currentTarget;
      const selectedTag = document.querySelector(".selected-tag");
      const pageTags = document.querySelectorAll(".page-tags");
      if (selectedTag) {
        selectedTag.remove();
        pageTags.forEach((t) => {
          t.classList.remove("color-white");
        });
      }

      let tag = document.createElement("div");
      tag.classList.add("selected-tag");
      div.appendChild(tag);
      div.classList.add("color-white");

      this.componentId = div.id;

      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    },
    openBarcodeModal() {
      this.isShowBarcodeModal = true;
    },
    closeBarcodeModal() {
      this.isShowBarcodeModal = false;
    },
    joinMember() {
      this.$api.members.joinMember(this.$route.params.id).then(() => {
        this.$swal.fire({
          icon: "success",
          title: "已加入店家 !",
          showConfirmButton: true,
        });
      });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      localStorage.setItem("shopId", this.$route.params.id);
    }
    if (!this.componentId) {
      this.componentId = "ShopIntroduce";
    }
    this.getStore();
  },
};
</script>

<style lang="scss">
.store-background {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 768px;
  height: 160px;
  color: white;
  background-color: #aaa;
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
}
.join-member-btn {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -32px;
  width: 350px;
  height: 64px;
  border-radius: 10px;
  background-color: white;
  font-size: 24px;
  color: #3e5ba9;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  .join-member-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    transition: all 0.2s;
  }
  .join-member-icon {
    margin-bottom: 2px;
  }
  .join-member-text {
    margin-left: 10px;
  }
  &:hover {
    transition: all 0.2s;
    .join-member-content {
      transition: all 0.2s;
      transform: scale(1.1);
    }
  }
}
</style>
