<template>
  <div style="box-sizing: border-box; padding: 0 32px">
    <div style="padding: 10px 0; font-size: 22px; font-weight: 500">
      {{ store.MemberTitle }}
    </div>
    <div id="memberContent" style="text-align: start">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { store: {} };
  },
  props: ["item"],
  watch: {
    item(value) {
      this.store = value;
      const memberContent = document.getElementById("memberContent");
      memberContent.innerHTML = this.store.MemberContent;
    },
  },
};
</script>
