import { httpGet } from "@/request/http";

let path = "integrals";
const url = process.env.VUE_APP_API_URL;

export default {
  getIntegrals(id) {
    return httpGet(`${url}/businesses/${id}/${path}/get`);
  },
  getUsedIntegrals(id) {
    return httpGet(`${url}/businesses/${id}/${path}/use`);
  },
};
