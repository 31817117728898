import { httpGet, httpPost, httpPut, httpDelete } from "@/request/http";

let path = "members";
const url = process.env.VUE_APP_API_URL;

export default {
  login(item) {
    return httpPost(`${url}/${path}/login`, item);
  },
  register(item) {
    return httpPost(`${url}/${path}/register`, item);
  },
  joinMember(id) {
    return httpPost(`${url}/businesses/${id}/${path}/join`);
  },
  forgetPwd(item) {
    return httpPost(`${url}/${path}/forget-password`, item);
  },
  changePwd(item) {
    return httpPost(`${url}/${path}/password`, item);
  },
  getInfo() {
    return httpGet(`${url}/${path}/info`);
  },
  saveInfo(item) {
    return httpPost(`${url}/${path}/info`, item);
  },
  getMember(id) {
    return httpGet(`${url}/businesses/${id}/member`);
  },
  getImage() {
    return httpGet(`${url}/${path}/image`, null, { responseType: "blob" });
  },
  updateImage(item) {
    return httpPut(`${url}/${path}/image`, item);
  },
  deleteImage() {
    return httpDelete(`${url}/${path}/image`);
  },
  saveLine(id, item) {
    return httpPost(`${url}/${id}/${path}/line`, item);
  },
  deleteLine() {
    return httpDelete(`${url}/${path}/lineuser`);
  },
};
