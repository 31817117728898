<template>
  <div class="modal-bg" style="z-index: 1000">
    <div
      class="log-out-modal"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 290px;
        width: 80%;
        height: 154px;
        background-color: white;
        border-radius: 16px;
      "
    >
      <div
        style="
          font-size: 20px;
          font-weight: 500;
          color: black;
          margin-bottom: 20px;
        "
      >
        確定登出?
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        "
      >
        <div
          style="
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 42px;
            color: #3e5ba9;
            background-color: white;
            border: 2px solid #3e5ba9;
            border-radius: 8px;
            margin-right: 8px;
          "
          @click="closeLogoutModal"
        >
          否
        </div>
        <div
          style="
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 42px;
            color: white;
            background-color: #3e5ba9;
            border: 2px solid #3e5ba9;
            border-radius: 8px;
            margin-left: 8x;
          "
          @click="logout"
        >
          是
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["isShowLogoutModal"],
  watch: {
    isShowLogoutModal(value) {
      const modal = document.querySelector(".modal-bg");
      if (value) {
        document.body.classList.add("modal-open");
        modal.classList.add("show-detail-modal");
        modal.addEventListener("click", this.clickPosition);
      } else {
        modal.removeEventListener("click", this.clickPosition);
        modal.classList.add("hide-detail-modal");
        setTimeout(() => {
          document.body.classList.remove("modal-open");
          modal.classList.remove("show-detail-modal");
          modal.classList.remove("hide-detail-modal");
        }, 150);
      }
    },
  },
  methods: {
    logout() {
      this.$emit("logout");
    },
    clickPosition(e) {
      if (e.target.classList.contains("modal-bg")) {
        this.closeLogoutModal();
      }
    },
    closeLogoutModal() {
      this.$emit("closeLogoutModal");
    },
  },
};
</script>
