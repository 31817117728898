import userAccounts from "@/request/apis/userAccountApi";
import businesses from "@/request/apis/businessApi";
import customers from "@/request/apis/customerApi";
import deposits from "@/request/apis/depositApi";
import stores from "@/request/apis/storeApi";
import members from "@/request/apis/memberApi";
import phones from "@/request/apis/phoneApi";
import integrals from "@/request/apis/integralApi";
import sales from "@/request/apis/saleApi";

export default {
  userAccounts,
  businesses,
  customers,
  deposits,
  stores,
  members,
  phones,
  integrals,
  sales,
};
