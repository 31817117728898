<template>
  <img class="login-bg" src="@/assets/login-background.jpg" alt="login-bg" />
  <form class="login" @submit.prevent="login">
    <div class="login-container">
      <div class="login-title">登入</div>
      <div class="account-input">
        <!-- <label class="standard-label label-blue" for="">帳號</label> -->
        <input
          class="login-input"
          type="text"
          v-model="account"
          placeholder="帳號"
          required
        />
      </div>
      <div class="password-input">
        <!-- <label class="standard-label label-blue" for="">密碼</label> -->
        <input
          id="pwd"
          class="login-input"
          type="password"
          v-model="password"
          @change="pwdChanged"
          placeholder="密碼"
          required
        />
        <div class="password-switch" @click="pwdSwitcher">
          <font-awesome-icon :icon="['fas', 'eye']" />
        </div>
      </div>
      <div
        class=""
        style="
          display: flex;
          justify-content: between;
          align-items: center;
          width: 100%;
        "
      >
        <div class="remember-me">
          <input
            type="checkbox"
            class="test"
            name="rememberMe"
            id="rememberMe"
            v-model="isRemember"
          />
          <label for="rememberMe">記住我</label>
        </div>
        <div class="forget-password">
          <div class="link-area" @click="forgetPassword">忘記密碼</div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column">
        <button class="submit-btn login-btn" type="submit">登入</button>
        <div class="register-link" style="margin-top: 20px">
          <div style="text-decoration: none; color: #3e5ba9" @click="register">
            註冊會員
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="system-name">
    <img src="@/assets/ushow-logo.svg" alt="" />
    <img src="@/assets/member-manager.svg" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      account: "",
      password: "",
      version: require("@/../package.json").version,
      imgFile: "",
      img: "",
      isRemember: false,
    };
  },
  watch: {
    password(newValue, oldValue) {
      if (oldValue) {
        localStorage.removeItem("hasRemember");
      }
    },
  },
  methods: {
    login() {
      console.log(this.$route);
      let pwd = "";
      const vm = this;
      if (localStorage.getItem("hasRemember") != "true") {
        pwd = this.$func.crypto.encrypt(this.password);
      } else {
        pwd = this.password;
      }
      if (this.$route.path == "/business/login") {
        this.$api.userAccounts.login(vm.account, pwd).then(() => {
          if (vm.isRemember) {
            localStorage.setItem("account", vm.account);
            localStorage.setItem("password", pwd);
            localStorage.setItem("hasRemember", true);
          } else {
            localStorage.removeItem("account");
            localStorage.removeItem("password");
            localStorage.removeItem("hasRemember");
          }
          vm.$router.push({
            name: "BusinessJoinMembership",
          });
        });
      } else {
        this.$api.members
          .login({
            Account: vm.account,
            Password: pwd,
          })
          .then((data) => {
            vm.$func.localStorage.set("token", data.Token);
            vm.$func.localStorage.set("imageURL", data.ImageURL);
            if (this.isRemember) {
              localStorage.setItem("account", vm.account);
              localStorage.setItem("password", pwd);
              localStorage.setItem("hasRemember", true);
            } else {
              localStorage.removeItem("account");
              localStorage.removeItem("password");
              localStorage.removeItem("hasRemember");
            }
            if (vm.$route.params.id) {
              vm.$router.push({
                name: "Shop",
                params: {
                  id: vm.$route.params.id,
                },
              });
            } else {
              vm.$router.push({
                name: "Home",
              });
            }
          })
          .catch((err) => {
            this.$swal.fire({
              icon: "error",
              title: err.data.Message,
              showConfirmButton: true,
            });
          });
      }
    },
    register() {
      if (this.$route.params.id) {
        this.$router.push({
          name: "ShopRegisterEnterNumber",
          params: {
            id: this.$route.params.id,
          },
        });
      } else {
        this.$router.push({
          name: "RegisterEnterNumber",
        });
      }
    },
    forgetPassword() {
      if (this.$route.params.id) {
        this.$router.push({
          path: `/${this.$route.params.id}/forget-password/enter-number`,
        });
      } else {
        this.$router.push({
          path: "/forget-password/enter-number",
        });
      }
    },
    pwdSwitcher() {
      const pwdInput = document.getElementById("pwd");
      if (pwdInput.type == "password") {
        pwdInput.type = "text";
      } else {
        pwdInput.type = "password";
      }
    },
  },
  mounted() {
    if (window.localStorage["account"] && window.localStorage["password"]) {
      this.account = localStorage.getItem("account");
      this.password = localStorage.getItem("password");
      this.isRemember = true;
    }
  },
};
</script>
