<template>
  <div style="margin-top: 10px; border-bottom: 2px solid #d9d9d9">
    <div style="display: flex; justify-content: center; align-item: center">
      <div
        id="canExchange"
        class="exchange-tags selected-exchange-tag"
        @click="changeTag"
      >
        待兌換
      </div>
      <div id="isExchanged" class="exchange-tags" @click="changeTag">
        已兌換
      </div>
    </div>
  </div>
  <div v-if="canExchange" class="my-goods-container">
    <div class="my-goods-group">
      <div class="paid-year">2023</div>
      <div class="my-goods-card-group">
        <div class="my-goods-card">
          <div class="card-image"></div>
          <div class="my-goods-card-content">
            <div class="card-name">紅茶拿鐵-大杯</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-exchange-time">兌換期限 2023-07-07</div>
          </div>
        </div>
        <div class="my-goods-card">
          <div class="card-image"></div>
          <div class="my-goods-card-content">
            <div class="card-name">紅茶拿鐵-大杯</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-exchange-time">兌換期限 2023-07-07</div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-goods-group">
      <div class="paid-year">2022</div>
      <div class="my-goods-card-group">
        <div class="my-goods-card">
          <div class="card-image"></div>
          <div class="my-goods-card-content">
            <div class="card-name">紅茶拿鐵-大杯</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-exchange-time">兌換期限 2022-07-07</div>
          </div>
        </div>
        <div class="my-goods-card">
          <div class="card-image"></div>
          <div class="my-goods-card-content">
            <div class="card-name">紅茶拿鐵-大杯</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-exchange-time">兌換期限 2022-07-07</div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-goods-group">
      <div class="paid-year">2021</div>
      <div class="my-goods-card-group">
        <div class="my-goods-card">
          <div class="card-image"></div>
          <div class="my-goods-card-content">
            <div class="card-name">紅茶拿鐵-大杯</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-exchange-time">兌換期限 2021-07-07</div>
          </div>
        </div>
        <div class="my-goods-card">
          <div class="card-image"></div>
          <div class="my-goods-card-content">
            <div class="card-name">紅茶拿鐵-大杯</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-exchange-time">兌換期限 2021-07-07</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="my-goods-container">
    <div class="my-goods-group">
      <div class="paid-year">2023</div>
      <div class="my-goods-card-group">
        <div class="my-goods-card">
          <div class="card-image"></div>
          <div class="my-goods-card-content">
            <div class="card-name">紅茶拿鐵-大杯</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-exchange-time">兌換日期 2023-07-07</div>
          </div>
        </div>
        <div class="my-goods-card">
          <div class="card-image"></div>
          <div class="my-goods-card-content">
            <div class="card-name">紅茶拿鐵-大杯</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-exchange-time">兌換日期 2023-07-07</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      canExchange: true,
    };
  },
  methods: {
    changeTag(e) {
      const tag = e.currentTarget;
      const exchangeTags = document.querySelectorAll(".exchange-tags");
      exchangeTags.forEach((t) => {
        t.classList.remove("selected-exchange-tag");
      });
      tag.classList.add("selected-exchange-tag");

      if (tag.id == "canExchange") {
        this.canExchange = true;
      } else if (tag.id == "isExchanged") {
        this.canExchange = false;
      }
    },
  },
  mounted() {},
};
</script>
