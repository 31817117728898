<template>
  <div class="my-history-group">
    <div class="paid-year">2023</div>
    <div class="my-history-card-group">
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20230707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2023-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20230707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2023-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20230707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2023-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20230707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2023-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20230707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2023-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20230707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2023-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
    </div>
  </div>

  <div class="my-history-group">
    <div class="paid-year">2022</div>
    <div class="my-history-card-group">
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20220707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2022-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20220707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2022-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20220707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2022-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20220707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2022-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20220707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2022-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
      <div class="my-history-card">
        <div class="my-history-card-content">
          <div class="card-number">20220707001</div>
          <div class="card-pay-type">Line pay</div>
          <div class="card-pay-time">2022-07-07 12:32</div>
        </div>
        <div class="card-pay-price">$390</div>
      </div>
    </div>
  </div>
</template>
