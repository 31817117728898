import { httpGet } from "@/request/http";

let path = "stores";
const url = process.env.VUE_APP_API_URL;

export default {
  getStores() {
    return httpGet(`${url}/${path}`);
  },
  getStore(id) {
    return httpGet(`${url}/${path}/${id}`);
  },
};
