import { createRouter, createWebHistory } from "vue-router";

import Dashboard from "@/components/Dashboard";
import Home from "@/views/Home";

import JoinMembership from "@/views/JoinMembership";
import Ushow from "@/views/Ushow";
import Login from "@/views/Login";

import Shop from "@/views/Shop";
import MemberProfile from "@/components/ushow/MemberProfile";
import ChangePassword from "@/components/ushow/ChangePassword";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Dashboard,
      children: [
        {
          name: "Home",
          path: "/",
          component: Home,
        },
        {
          name: "Login",
          path: "/login",
          component: Login,
        },
        {
          name: "BusinessLogin",
          path: "business/login",
          component: Login,
        },
        {
          name: "BusinessJoinMembership",
          path: "business/joinmember",
          component: JoinMembership,
        },
        {
          name: "MemberProfile",
          path: "/member-profile",
          component: MemberProfile,
        },
        {
          name: "ChangePassword",
          path: "/member-profile/change-password",
          component: ChangePassword,
        },
        {
          name: "RegisterEnterNumber",
          path: "/register/enter-number",
          component: Ushow,
        },
        {
          name: "RegisterCheckVerify",
          path: "/register/check-verify",
          component: Ushow,
        },
        {
          name: "RegisterSetPassword",
          path: "/register/set-password",
          component: Ushow,
        },
        {
          name: "FillOutProfile",
          path: "/register/fill-out-profile",
          component: Ushow,
        },
        {
          name: "ForgetPasswordEnterNumber",
          path: "/forget-password/enter-number",
          component: Ushow,
        },
        {
          name: "ForgetPasswordCheckVerify",
          path: "/forget-password/check-verify",
          component: Ushow,
        },
        {
          name: "ForgetPasswordSetPassword",
          path: "/forget-password/set-password",
          component: Ushow,
        },
        {
          name: "Shop",
          path: "/:id",
          component: Shop,
        },
        {
          name: "ShopLogin",
          path: "/:id/login",
          component: Login,
        },
        {
          name: "ShopRegisterEnterNumber",
          path: "/:id/register/enter-number",
          component: Ushow,
        },
        {
          name: "ShopRegisterCheckVerify",
          path: "/:id/register/check-verify",
          component: Ushow,
        },
        {
          name: "ShopRegisterSetPassword",
          path: "/:id/register/set-password",
          component: Ushow,
        },
        {
          name: "ShopFillOutProfile",
          path: "/:id/register/fill-out-profile",
          component: Ushow,
        },
        {
          name: "ShopForgetPasswordEnterNumber",
          path: "/:id/forget-password/enter-number",
          component: Ushow,
        },
        {
          name: "ShopForgetPasswordCheckVerify",
          path: "/:id/forget-password/check-verify",
          component: Ushow,
        },
        {
          name: "ShopForgetPasswordSetPassword",
          path: "/:id/forget-password/set-password",
          component: Ushow,
        },
      ],
    },
  ],
});

export default router;
