export function get(name) {
  return window.localStorage[name];
}

export function set(name, value) {
  window.localStorage[name] = value;
}

export function removeAll() {
  window.localStorage.clear();
}
