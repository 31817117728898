<template>
  <div class="modal-bg">
    <div
      class="barcode-modal"
      style="
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: 350px;
        width: 80%;
        height: 432px;
        background-color: white;
        border-radius: 20px;
      "
    >
      <div
        style="
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          color: black;
          font-size: 24px;
          padding: 16px 20px 8px 0;
        "
        @click="closeBarcodeModal"
      >
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </div>
      <div style="color: black">會員條碼</div>
      <vue3-barcode class="barcode-container" v-if="customerNumber" :value="customerNumber" :height="80"></vue3-barcode>
      <div class="qrcode-container" style="width: 160px; height: 160px">
        <qrcode-vue class="qrcode" v-if="customerNumber" :value="customerNumber" :size="150" ></qrcode-vue>
      </div>
      <div
        style="
          text-align: start;
          padding: 0 32px;
          color: #828282;
          font-size: 14px;
          margin-top: 5px;
        "
      >
        提醒您，以翻拍、截圖方式傳輸至其他設備可能導致條碼無效。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["isShowBarcodeModal", "customerNumber"],
  watch: {
    isShowBarcodeModal(value) {
      const modal = document.querySelector(".modal-bg");
      if (value) {
        document.body.classList.add("modal-open");
        modal.classList.add("show-detail-modal");
      } else {
        modal.classList.add("hide-detail-modal");
        setTimeout(() => {
          document.body.classList.remove("modal-open");
          modal.classList.remove("show-detail-modal");
          modal.classList.remove("hide-detail-modal");
        }, 150);
      }
    },
  },
  methods: {
    closeBarcodeModal() {
      this.$emit("closeBarcodeModal");
    },
  },
};
</script>

<style lang="scss">
// .barcode-container {
//   height: 100%;
//   svg {
//   }
// }
.qrcode-container {
  padding: 0 20px;
}
// .qrcode {
//   width: 100% !important;
//   height: 100% !important;
// }
</style>