<template>
  <div id="second-step">
    <div style="padding: 50px 0 10px 0; font-size: 24px; font-weight: 500">
      手機驗證
    </div>
    <div style="padding: 0 0 10px 0">請輸入傳送至以下電話號碼的6位數驗證碼</div>
    <div v-if="!isEdit" style="color: #000; height: 24px">
      +886 {{ phoneNumber }}
    </div>
    <div
      v-else
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        height: 24px;
      "
    >
      +886
      <input
        type="tel"
        style="
          margin-left: 5px;
          padding: 0 5px;
          width: 120px;
          height: 24px;
          font-size: 14px;
          border: 1px solid #dedede;
          border-radius: 4px;
        "
        v-model="newPhoneNumber"
      />
    </div>
    <div style="color: #3e5ba9; padding: 0 0 20px 0">
      <div v-if="!isEdit" style="cursor: pointer" @click="editNumber">變更</div>
      <div
        v-else
        style="display: flex; justify-content: center; align-items: center"
      >
        <div style="cursor: pointer; margin-right: 5px" @click="changeNumber">
          更新
        </div>
        <div style="cursor: pointer; color: #aaa" @click="isEdit = false">
          取消
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      "
    >
      <div
        style="
          width: 40px;
          height: 40px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          overflow: hidden;
        "
      >
        <input
          class="verification-code-input"
          style="
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          "
          type="tel"
          minlength="0"
          maxlength="1"
          @input="tabEvent(0, $event)"
        />
      </div>
      <div
        style="
          width: 40px;
          height: 40px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          overflow: hidden;
        "
      >
        <input
          class="verification-code-input"
          style="
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          "
          type="tel"
          minlength="0"
          maxlength="1"
          @input="tabEvent(1, $event)"
        />
      </div>
      <div
        style="
          width: 40px;
          height: 40px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          overflow: hidden;
        "
      >
        <input
          class="verification-code-input"
          style="
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          "
          type="tel"
          minlength="0"
          maxlength="1"
          @input="tabEvent(2, $event)"
        />
      </div>
      <div
        style="
          width: 40px;
          height: 40px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          overflow: hidden;
        "
      >
        <input
          class="verification-code-input"
          style="
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          "
          type="tel"
          minlength="0"
          maxlength="1"
          @input="tabEvent(3, $event)"
        />
      </div>
      <div
        style="
          width: 40px;
          height: 40px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          overflow: hidden;
        "
      >
        <input
          class="verification-code-input"
          style="
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          "
          type="tel"
          minlength="0"
          maxlength="1"
          @input="tabEvent(4, $event)"
        />
      </div>
      <div
        style="
          width: 40px;
          height: 40px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          overflow: hidden;
        "
      >
        <input
          class="verification-code-input"
          style="
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          "
          type="tel"
          minlength="0"
          maxlength="1"
          @input="tabEvent(5, $event)"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0 20px 0;
      "
    >
      <button
        class="submit-btn"
        style="text-decoration: none; padding-bottom: 3px; font-weight: 500"
        @click="checkVerificationCode"
      >
        驗證
      </button>
    </div>
    <div
      v-if="seconds >= 0"
      id="countdown-timer"
      style="color: #f06c6c; padding: 0 0 3px 0"
    >
      {{ timer }}
    </div>
    <div style="display: flex; justify-content: center; aling-items: center">
      <div>沒收到簡訊？</div>
      <div
        v-if="seconds < 0"
        style="margin-left: 5px; color: #3e5ba9"
        @click="setCountdownTimer"
      >
        重新傳送
      </div>
      <div v-else style="margin-left: 5px; color: #828282">重新傳送</div>
    </div>
    <div style="color: #3e5ba9; padding-top: 10px">
      <span style="cursor: pointer" @click="backToLogin">返回登入</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phoneNumber: "",
      newPhoneNumber: "",
      seconds: 90,
      timer: "",
      isEdit: false,
    };
  },
  methods: {
    editNumber() {
      this.newPhoneNumber = this.phoneNumber;
      this.isEdit = true;
    },
    changeNumber() {
      this.phoneNumber = this.newPhoneNumber;
      this.newPhoneNumber = "";
      this.isEdit = false;
    },
    checkVerificationCode() {
      const codes = document.querySelectorAll(".verification-code-input");
      let verifyCode = "";
      codes.forEach((c) => {
        verifyCode += c.value;
      });
      this.$api.phones
        .checkVerify({
          Cellphone: this.phoneNumber,
          VerifyCode: verifyCode,
          BusinessId: this.$route.params.id,
        })
        .then(() => {
          this.$swal
            .fire({
              icon: "success",
              title: "驗證成功 !",
              showConfirmButton: true,
            })
            .then((isConfirmed) => {
              localStorage.setItem("verifyCode", verifyCode);
              if (isConfirmed) {
                this.$router.push({
                  name: "RegisterSetPassword",
                  params: {
                    id: this.$route.params.id,
                  },
                });
              }
            });
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.data.Message,
            showConfirmButton: true,
          });
        });
    },
    tabEvent(index, e) {
      const allVerificationCode = document.querySelectorAll(
        ".verification-code-input"
      );
      if (e.inputType == "deleteContentBackward") {
        if (index > 0) allVerificationCode[index - 1].focus();
        return;
      }
      if (index + 1 < allVerificationCode.length) {
        allVerificationCode[index + 1].focus();
      }
    },
    reSendVerificationCode() {
      this.$api.phones
        .register({
          Cellphone: this.phoneNumber,
          BusinessId: this.$route.params.id,
        })
        .then((res) => {
          this.setCountdownTimer();
          if (res.status == 409) {
            this.$router.push({
              name: "RegisterSetPassword",
              params: {
                id: this.$route.params.id,
              },
            });
          }
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.data.Message,
            showConfirmButton: true,
          });
        });
    },
    setCountdownTimer() {
      this.seconds = 90;
      this.timer = `${Math.floor(this.seconds / 60)
        .toString()
        .padStart(2, "0")}:${(this.seconds % 60).toString().padStart(2, "0")}`;
      let countdown = setInterval(() => {
        this.seconds -= 1;
        if (this.seconds < 0) {
          clearInterval(countdown);
          return;
        }
        this.timer = `${Math.floor(this.seconds / 60)
          .toString()
          .padStart(2, "0")}:${(this.seconds % 60)
          .toString()
          .padStart(2, "0")}`;
      }, 1000);
    },
    backToLogin() {
      if (this.$route.params.id) {
        this.$router.push({
          name: "ShopLogin",
          params: {
            id: this.$route.params.id,
          },
        });
      } else {
        this.$router.push({
          name: "Login",
        });
      }
    },
  },
  mounted() {
    this.phoneNumber = localStorage.getItem("phoneNumber");

    const firstVerificationCode = document.querySelector(
      ".verification-code-input"
    );
    firstVerificationCode.focus();
    this.setCountdownTimer();
  },
};
</script>
