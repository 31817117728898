<template>
  <div
    style="
      max-width: 768px;
      width: 100vw;
      padding: 0 20px;
      height: 100%;
      min-height: 100vh;
      background-color: #f7f6f6;
    "
  >
    <div>
      <div
        style="
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
        "
      >
        <div style="font-size: 24px; font-weight: 500; color: #000">
          Ushow會員
        </div>

        <div
          style="
            cursor: pointer;
            position: absolute;
            right: 0;
            font-size: 16px;
            color: #3e5ba9;
          "
        >
          <div style="color: #3e5ba9" @click="openLogoutModal">登出</div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        padding: 10px 0;
      "
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30%;
          padding: 10px 0;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 56px;
            height: 56px;
            font-size: 28px;
            border-radius: 28px;
            background-color: #3e5ba9;
            color: #fff;
          "
        >
          {{ firstWord }}
        </div>
      </div>
      <div style="width: 70%; text-align: start">
        <div style="">{{ member.Name }}</div>
        <div style="">{{ member.Cellphone }}</div>
        <div style="cursor: pointer; color: #3e5ba9" @click="goToProfile">
          查看會員資料
        </div>
      </div>
    </div>
    <div>
      <div
        style="font-size: 24px; font-weight: 500; color: #000; padding: 18px 0"
      >
        我的店家
      </div>
      <!-- <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 40px;
          border-radius: 20px;
          background-color: #fff;
          margin-bottom: 30px;
        "
      >
        <div style="padding: 0 0 2px 20px; color: #828282">搜尋</div>
        <div style="padding: 0 20px 2px 0; color: #828282">
          <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
        </div>
      </div> -->
      <div>
        <div
          v-for="(store, index) in stores"
          :key="index"
          class="member-store-card"
          @click="goToStore(store.BusinessId)"
        >
          <div class="member-store-background">
            <img
              v-if="store.BackgroundImageURL"
              :src="store.BackgroundImageURL"
              style="
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                filter: brightness(50%);
              "
              alt=""
            />
            <div
              class="member-store-logo"
              :style="
                !store.LogoURL
                  ? 'background-color: white;'
                  : 'background-color: none;'
              "
            >
              <img
                v-if="store.LogoURL"
                :src="store.LogoURL"
                style="height: 100%"
                alt=""
              />
            </div>
          </div>
          <div v-if="store.StoreName" class="member-store-name">
            {{ store.StoreName }}
          </div>
          <div v-else class="member-store-name">(未設定名稱)</div>
        </div>
      </div>
    </div>
  </div>
  <LogoutModal
    :isShowLogoutModal="isShowLogoutModal"
    @logout="logout"
    @closeLogoutModal="closeLogoutModal"
  ></LogoutModal>
</template>

<script>
import LogoutModal from "@/components/LogoutModal";

export default {
  data() {
    return {
      stores: [],
      member: {},
      firstWord: "",
      isShowLogoutModal: false,
    };
  },
  components: { LogoutModal },
  methods: {
    goToStore(id) {
      this.$router.push({
        name: "Shop",
        params: { id: id },
      });
    },
    goToProfile() {
      this.$router.push({
        name: "MemberProfile",
      });
    },
    logout() {
      this.$api.userAccounts.logout();
      this.$router.push({
        name: "Login",
      });
    },
    openLogoutModal() {
      this.isShowLogoutModal = true;
    },
    closeLogoutModal() {
      this.isShowLogoutModal = false;
    },
  },
  mounted() {
    const vm = this;
    localStorage.removeItem("shopId");
    this.$api.stores.getStores().then((data) => {
      vm.stores = data;
      vm.$api.members.getInfo().then((item) => {
        vm.member = item;
        vm.firstWord = this.member.Name.substr(0, 1);
      });
    });
  },
};
</script>

<style lang="scss">
.member-store-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 130px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #aaa;
  background-size: cover;
  background-position: center;
}
.member-store-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
  color: white;
}
.member-store-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  color: #000;
  overflow: hidden;
  z-index: 2;
}
.member-store-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30%;
  background-color: #fff;
  z-index: 2;
}
</style>
