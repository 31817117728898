import { createApp } from "vue";
import { createPinia } from "pinia";

import axios from "axios";
import VueAxios from "vue-axios";
import Loading from "vue-loading-overlay";
import Vue3Barcode from "vue3-barcode";
import QrcodeVue from "qrcode.vue";
import "vue-loading-overlay/dist/css/index.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// 引入 VeeValidate 元件跟功能
import { Field, Form, ErrorMessage, defineRule, configure } from "vee-validate";
// 引入 VeeValidate 的驗證規則
import AllRules from "@vee-validate/rules";
// 引入 VeeValidate 的 i18n 功能
import { localize, setLocale } from "@vee-validate/i18n";
// 引入 VeeValidate 的繁體中文語系檔
import zhTW from "@vee-validate/i18n/dist/locale/zh_TW.json";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import apis from "@/request/api";
import funcs from "@/funcs";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

/* add icons to the library */
library.add(fas);
library.add(fab);
library.add(far);

// 使用 Object.keys 將 AllRules 轉為陣列並使用 forEach 迴圈將驗證規則加入 VeeValidate
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

// 將當前 VeeValidate 的語系設定為繁體中文
configure({
  generateMessage: localize({ zh_TW: zhTW }),
  validateOnInput: true,
});
setLocale("zh_TW");

const options = {
  confirmButtonColor: "#3e5ba9",
  denyButtonColor: "dc3741",
  cancelButtonColor: "727272",
};

const app = createApp(App);
const pinia = createPinia();

app.config.globalProperties.$api = apis;
app.config.globalProperties.$func = funcs;
app.use(VueAxios, axios);
app.use(router);
app.use(store);
app.use(pinia);
app.use(VueSweetalert2, options);

app.component("Loading", Loading);
app.component("VField", Field);
app.component("VForm", Form);
app.component("ErrorMessage", ErrorMessage);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("Vue3Barcode", Vue3Barcode);
app.component("QrcodeVue", QrcodeVue);

app.mount("#app");
