<template>
  <form id="register-enter-number">
    <div style="padding: 50px 0 30px 0; font-size: 24px; font-weight: 500">
      註冊
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 40px;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        cursor: pointer;
        overflow: hidden;
      "
    >
      <div style="height: 100%">
        <select
          class="phone-select"
          style="width: 70px; padding-right: 0"
          disabled="disabled"
        >
          <option value="">+886</option>
        </select>
      </div>
      <div
        class
        style="
          width: calc(100% - 70px);
          height: 100%;
          border-left: 1px solid #d4d4d4;
        "
      >
        <input
          class="phone-input"
          style="width: 100%"
          type="tel"
          v-model="phoneNumber"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0 20px 0;
      "
    >
      <button
        class="submit-btn"
        type="button"
        style="text-decoration: none; padding-bottom: 3px; font-weight: 500"
        @click="sendVerificationCode"
      >
        傳送驗證碼
      </button>
    </div>
    <div>網站將會透過簡訊傳送驗證碼</div>
    <div style="color: #3e5ba9; padding-top: 10px">
      <span style="cursor: pointer" @click="backToLogin">返回</span>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      phoneNumber: "",
    };
  },
  methods: {
    sendVerificationCode() {
      if (!this.phoneNumber) {
        this.$swal.fire({
          icon: "error",
          title: "請輸入正確的電話號碼",
          showConfirmButton: true,
        });
        return;
      }
      this.$api.phones
        .register({
          Cellphone: this.phoneNumber,
          BusinessId: this.$route.params.id,
        })
        .then(() => {
          localStorage.setItem("phoneNumber", this.phoneNumber);
          if (this.$route.params.id) {
            this.$router.push({
              name: "ShopRegisterCheckVerify",
              params: {
                id: this.$route.params.id,
              },
            });
          } else {
            this.$router.push({
              name: "RegisterCheckVerify",
            });
          }
        })
        .catch((err) => {
          if (err.status == 409) {
            localStorage.setItem("phoneNumber", this.phoneNumber);
            this.$swal
              .fire({
                icon: "success",
                title: "號碼已驗證\n將跳轉至密碼設定頁面",
                showConfirmButton: true,
              })
              .then((isConfirmed) => {
                if (isConfirmed) {
                  if (this.$route.params.id) {
                    this.$router.push({
                      name: "ShopRegisterSetPassword",
                      params: {
                        id: this.$route.params.id,
                      },
                    });
                  } else {
                    this.$router.push({
                      name: "RegisterSetPassword",
                    });
                  }
                }
              });
          } else {
            this.$swal.fire({
              icon: "error",
              title: err.data.Message,
              showConfirmButton: true,
            });
          }
        });
    },
    backToLogin() {
      window.history.back();
    },
  },
};
</script>
