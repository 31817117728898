import { httpPost } from "@/request/http";
import func from "@/funcs";
import Swal from "sweetalert2";

export default {
  login(account, password, businessId) {
    return new Promise((resolve, reject) => {
      httpPost(`${process.env.VUE_APP_BUSINESS_API_URL}/token`, {
        account,
        password,
        businessId,
      })
        .then((data) => {
          func.localStorage.set("roleType", data.RoleType);
          func.localStorage.set("token", data.Token);
          resolve(data);
        })
        .catch((error) => {
          console.log(!error.data.Message);
          if (error.data.Message) {
            Swal.fire({
              icon: "error",
              title: error.data.Message,
              showConfirmButton: true,
              confirmButtonColor: "#3e5ba9",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "登入失敗 !",
              showConfirmButton: true,
              confirmButtonColor: "#3e5ba9",
            });
          }
          reject(error);
        });
    });
  },
  logout() {
    localStorage.removeItem("token");
  },
};
