<template>
  <form id="forget-password-enter-number">
    <div style="padding: 50px 0 10px 0; font-size: 24px; font-weight: 500">
      忘記密碼
    </div>
    <div style="padding: 0 0 20px 0">請輸入註冊時的手機號碼</div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 40px;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        cursor: pointer;
        overflow: hidden;
      "
    >
      <div style="height: 100%">
        <select
          class="phone-select"
          style="width: 70px; padding-right: 0"
          disabled="disabled"
        >
          <option value="">+886</option>
        </select>
      </div>
      <div
        class
        style="
          width: calc(100% - 70px);
          height: 100%;
          border-left: 1px solid #d4d4d4;
        "
      >
        <input
          class="phone-input"
          style="width: 100%"
          type="tel"
          v-model="phoneNumber"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0 20px 0;
      "
    >
      <button
        class="submit-btn"
        type="button"
        style="text-decoration: none; padding-bottom: 3px; font-weight: 500"
        @click="sendVerificationCode"
      >
        傳送驗證碼
      </button>
    </div>
    <div>網站將會透過簡訊傳送驗證碼</div>
    <div style="color: #3e5ba9; padding-top: 10px">
      <span style="cursor: pointer" @click="backToLogin">返回</span>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      phoneNumber: "",
    };
  },
  methods: {
    sendVerificationCode() {
      if (!this.phoneNumber) {
        this.$swal.fire({
          icon: "error",
          title: "請輸入正確的電話號碼",
          showConfirmButton: true,
        });
        return;
      }
      localStorage.setItem("phoneNumber", this.phoneNumber);
      this.$api.phones
        .forgetPwd({
          Cellphone: this.phoneNumber,
          BusinessId: this.$route.params.id,
        })
        .then(() => {
          this.$router.push({
            name: "ForgetPasswordCheckVerify",
            params: {
              id: this.$route.params.id,
            },
          });
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.data.Message,
            showConfirmButton: true,
          });
        });
    },
    backToLogin() {
      window.history.back();
    },
  },
};
</script>
