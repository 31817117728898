<template>
  <div style="margin-top: 10px; border-bottom: 2px solid #d9d9d9">
    <div style="display: flex; justify-content: center; align-item: center">
      <div id="canUse" class="use-tags selected-use-tag" @click="changeTag">
        可使用
      </div>
      <div id="isUsed" class="use-tags" @click="changeTag">已使用</div>
    </div>
  </div>

  <div v-if="canUse" class="my-deposit-container">
    <div class="my-deposit-group">
      <div class="paid-year">2023</div>
      <div class="my-history-card-group">
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">2023-07-07 12:32</div>
          </div>
          <div class="card-pay-price">$390</div>
        </div>
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">2023-07-07 12:32</div>
          </div>
          <div class="card-pay-price">$390</div>
        </div>
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">2023-07-07 12:32</div>
          </div>
          <div class="card-pay-price">$390</div>
        </div>
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">2023-07-07 12:32</div>
          </div>
          <div class="card-pay-price">$390</div>
        </div>
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">2023-07-07 12:32</div>
          </div>
          <div class="card-pay-price">$390</div>
        </div>
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">2023-07-07 12:32</div>
          </div>
          <div class="card-pay-price">$390</div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="my-deposit-container">
    <div class="my-deposit-group">
      <div class="paid-year">2023</div>
      <div class="my-history-card-group">
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">使用日期 2023-07-07</div>
          </div>
          <div class="card-pay-price">-$390</div>
        </div>
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">使用日期 2023-07-07</div>
          </div>
          <div class="card-pay-price">-$390</div>
        </div>
        <div class="my-history-card">
          <div class="my-history-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-pay-type">Line pay</div>
            <div class="card-pay-time">使用日期 2023-07-07</div>
          </div>
          <div class="card-pay-price">-$390</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      canUse: true,
    };
  },
  methods: {
    changeTag(e) {
      const tag = e.currentTarget;
      const useTags = document.querySelectorAll(".use-tags");
      useTags.forEach((t) => {
        t.classList.remove("selected-use-tag");
      });
      tag.classList.add("selected-use-tag");

      if (tag.id == "canUse") {
        this.canUse = true;
      } else if (tag.id == "isUsed") {
        this.canUse = false;
      }
    },
  },
};
</script>
