import { createStore } from "vuex";

const store = createStore({
  state: {
    isLoading: false,
  },
  getters: {},
  mutations: {
    LOADING(state, status) {
      state.isLoading = status;
    },
  },
  actions: {
    updateLoading(context, status) {
      context.commit("LOADING", status);
    },
  },
});

export default store;
