<template>
  <div class="flex-center-container pb-50">
    <form class="join-membership" @submit.prevent="check">
      <div class="container">
        <div
          class=""
          style="padding: 30px 0; font-size: 24px; font-weight: 500"
        >
          <span>歡迎加入Ushow會員</span>
        </div>
        <div class="form-content" style="">
          <div class="customer-name customer-question">
            <label class="standard-label label-blue" for="name">姓名*</label>
            <input
              class="standard-input"
              name="name"
              type="text"
              v-model="item.name"
              required
            />
          </div>
          <div class="customer-gender customer-question">
            <label class="standard-label label-blue" for="gender">性別*</label>
            <div class="join-options gender-options">
              <div
                class="join-option gender-option"
                data-option="男性"
                @click="changeGender('男性', $event)"
              >
                <div class="selected-circle">
                  <font-awesome-icon icon="fa-solid fa-check" />
                </div>
                男性
              </div>
              <div
                class="join-option gender-option"
                data-option="女性"
                @click="changeGender('女性', $event)"
              >
                <div class="selected-circle">
                  <font-awesome-icon icon="fa-solid fa-check" />
                </div>
                女性
              </div>
              <div
                class="join-option gender-option"
                data-option="其他"
                @click="changeGender('其他', $event)"
              >
                <div class="selected-circle">
                  <font-awesome-icon icon="fa-solid fa-check" />
                </div>
                其他
              </div>
            </div>
          </div>
          <div></div>
          <div
            class="customer-birthday customer-question"
            style="margin-bottom: 0"
          >
            <label class="standard-label label-blue" for="birthday"
              >生日*</label
            >
            <div class="birthdate-inputs">
              <select
                class="year-input"
                name="year"
                id="year"
                v-model="item.birthYear"
                required
              >
                <option
                  class="birthdate-option"
                  v-for="(year, index) in years"
                  :key="index"
                  :value="year"
                >
                  {{ year }}
                </option>
              </select>
              <label class="birthdate-label" for="year">年</label>
              <select
                class="month-input"
                name="month"
                id="month"
                v-model="item.birthMonth"
                required
              >
                <option
                  class="birthdate-option"
                  v-for="(month, index) in months"
                  :key="index"
                  :value="month"
                >
                  {{ month }}
                </option>
              </select>
              <label class="birthdate-label" for="month">月</label>
              <select
                class="day-input"
                name="day"
                id="day"
                v-model="item.birthDay"
                required
              >
                <option
                  class="birthdate-option"
                  v-for="(day, index) in days"
                  :key="index"
                  :value="day"
                >
                  {{ day }}
                </option>
              </select>
              <label class="birthdate-label" for="day">日</label>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 3px 0 12px 0;
              padding: 0 20px;
            "
          >
            <label class="standard-label label-blue" for="hint"></label>
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: calc(100% - 55px);
                margin-bottom: 3px;
                color: #aaa;
                font-size: 14px;
                font-weight: 200;
              "
            >
              生日將用於生日禮發放，填寫後不可更改
            </div>
          </div>
          <div class="customer-phone customer-question">
            <label class="standard-label label-blue" for="phone">電話*</label>
            <!-- <input
                class="standard-input"
                type="text"
                name="phone"
                v-model="item.phone"
                required
              /> -->
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: calc(100% - 55px);
                margin-bottom: 3px;
              "
            >
              +886 {{ item.phone }}
            </div>
          </div>
          <div class="customer-email customer-question">
            <label class="standard-label label-blue" for="email">Email</label>
            <input
              class="standard-input"
              type="text"
              name="email"
              v-model="item.email"
            />
          </div>
          <div class="customer-country customer-question">
            <label class="standard-label label-blue" for="country">國家</label>
            <div class="join-options country-options">
              <div
                class="join-option country-option"
                data-option="0"
                @click="changeCountry(0, $event)"
              >
                <div class="selected-circle">
                  <font-awesome-icon icon="fa-solid fa-check" />
                </div>
                台灣
              </div>
              <div
                class="join-option country-option"
                data-option="1"
                @click="changeCountry(1, $event)"
              >
                <div class="selected-circle">
                  <font-awesome-icon icon="fa-solid fa-check" />
                </div>
                其他
              </div>
            </div>
          </div>
          <div
            v-if="item.country == 1"
            class="customer-country-else customer-question"
          >
            <label class="standard-label label-blue" for="elseCountry"
              >國名</label
            >
            <input
              class="standard-input"
              type="text"
              name="elseCountry"
              v-model="item.elseCountry"
            />
          </div>
          <div class="customer-address customer-question">
            <label class="standard-label label-blue" for="address">地址</label>
            <input
              class="standard-input"
              type="text"
              name="address"
              v-model="item.address"
            />
          </div>
          <!-- <div class="customer-address customer-question">
              <label class="standard-label label-blue" for="pickupAddress"
                >取貨方式</label
              >
              <input
                class="standard-input"
                type="text"
                name="pickupAddress"
                v-model="item.PickupAddress"
              />
            </div> -->
        </div>
        <div
          class=""
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0 0 0;
          "
        >
          <button
            class=""
            type="button"
            style="
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: white;
              color: #3e5ba9;
              margin: 0 10px;
              width: 160px;
              height: 44px;
              border: 1px solid #3e5ba9;
              border-radius: 8px;
              font-size: 20px;
              font-weight: 600;
            "
            @click="backToLogin"
          >
            返回
          </button>
          <button class="submit-btn" type="submit">送出</button>
        </div>
      </div>
    </form>
  </div>

  <join-member-check
    :isShowModal="isShowModal"
    :item="item"
    @onSaved="save"
    @onClosed="close"
  ></join-member-check>
</template>

<script>
import joinMemberCheck from "@/components/JoinMemberCheck";

export default {
  data() {
    return {
      business: {},
      imgFile: "",
      img: "",
      item: {
        name: "",
        gender: "",
        birthYear: "",
        birthMonth: "1",
        birthDay: "1",
        phone: "",
        email: "",
        country: null,
        elseCountry: "",
        address: "",
        pickupAddress: "",
      },
      years: [],
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      days: [],
      isShowModal: false,
    };
  },
  components: { joinMemberCheck },
  watch: {
    "item.birthMonth": function (value) {
      this.setDays(value);
    },
  },
  methods: {
    check() {
      if (!this.item.gender) {
        if (!this.item.gender) {
          let genders = document.querySelectorAll(".gender-option");
          genders.forEach((g) => {
            g.classList.add("invalid");
          });
        }
        return;
      }
      this.isShowModal = true;
    },
    changeGender(gender, e) {
      if (this.item.gender == gender) {
        return;
      }
      this.item.gender = gender;
      let genders = document.querySelectorAll(".gender-option");
      genders.forEach((g) => {
        if (g.classList.contains("invalid")) {
          g.classList.remove("invalid");
        }
        g.classList.remove("selected");
      });
      e.target.classList.add("selected");
    },
    changeCountry(country, e) {
      if (this.item.country == country) {
        e.target.classList.remove("selected");
        this.item.country = null;
        return;
      }
      this.item.country = country;
      let countrys = document.querySelectorAll(".country-option");
      countrys.forEach((c) => {
        if (c.classList.contains("invalid")) {
          c.classList.remove("invalid");
        }
        c.classList.remove("selected");
      });
      e.target.classList.add("selected");
    },
    save() {
      this.item = {
        name: "",
        gender: "",
        birthYear: "",
        birthMonth: "",
        birthDay: "",
        phone: "",
        email: "",
        country: null,
        elseCountry: "",
        address: "",
        pickupAddress: "",
      };
      let genders = document.querySelectorAll(".gender-option");
      genders.forEach((g) => {
        g.classList.remove("selected");
      });
    },
    close() {
      this.isShowModal = false;
    },
    setDays(month) {
      this.days = [];
      if (
        month == "1" ||
        month == "3" ||
        month == "5" ||
        month == "7" ||
        month == "8" ||
        month == "10" ||
        month == "12"
      ) {
        for (let i = 0; i < 31; i++) {
          this.days.push(`${i + 1}`);
        }
      } else if (month == "2") {
        for (let i = 0; i < 29; i++) {
          this.days.push(`${i + 1}`);
        }
      } else {
        for (let i = 0; i < 30; i++) {
          this.days.push(`${i + 1}`);
        }
      }
    },
    backToLogin() {
      this.$router.push({
        name: "Login",
      });
    },
  },
  mounted() {
    const nowYear = new Date().getFullYear();
    for (let i = 0; i < 100; i++) {
      this.years.push(`${nowYear - i}`);
    }
    this.setDays(this.item.birthMonth);

    // this.$api.businesses.getImage().then((file) => {
    //   if (file.size > 0) {
    //     vm.imgFile = file;
    //     let reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //       vm.img = reader.result;
    //     };
    //   }
    // });
    // this.$api.businesses.getPart().then((data) => {
    //   vm.business = data;
    // });

    let localItem = {};
    if (window.localStorage["item"]) {
      localItem = JSON.parse(window.localStorage["item"]);
      this.item = localItem;

      let genders = document.querySelectorAll(".gender-option");
      let countrys = document.querySelectorAll(".country-option");

      genders.forEach((g) => {
        if (g.dataset.option == this.item.gender) {
          g.classList.add("selected");
        }
      });
      countrys.forEach((c) => {
        if (c.dataset.option == this.item.country) {
          c.classList.add("selected");
        }
      });
    } else {
      this.item.birthYear = this.years[0];
    }

    if (localStorage.getItem("phoneNumber")) {
      this.item.phone = localStorage.getItem("phoneNumber");
    }

    window.onbeforeunload = () => {
      window.localStorage["item"] = JSON.stringify(this.item);
    };
  },
};
</script>
