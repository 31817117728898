import { httpGet } from "@/request/http";

let path = "sales";
const url = process.env.VUE_APP_API_URL;

export default {
  getSales(id) {
    return httpGet(`${url}/businesses/${id}/${path}/list`);
  },
};
