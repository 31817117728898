<template>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0 15px 0;
    "
  >
    <div
      style="
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 78px;
        border: 2px solid #3e5ba9;
        border-radius: 8px;
        background-color: white;
      "
      @click="openPointDetailModal"
    >
      <div style="display: flex; justify-content: center; align-items: center">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background-color: #3e5ba9;
            color: white;
            font-size: 18px;
          "
        >
          P
        </div>
        <div
          style="
            margin: 0 0 3px 5px;
            color: black;
            font-size: 24px;
            font-weight: 500;
          "
        >
          10
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: #3e5ba9;
        "
      >
        <div>30天內失效</div>
        <div>
          <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top: 10px; border-bottom: 2px solid #d9d9d9">
    <div style="display: flex; justify-content: center; align-item: center">
      <div id="isGained" class="use-tags selected-use-tag" @click="changeTag">
        已賺取
      </div>
      <div id="isUsed" class="use-tags" @click="changeTag">已使用</div>
      <div id="isInvalid" class="use-tags" @click="changeTag">已失效</div>
    </div>
  </div>

  <div v-if="canUse == 0" class="my-point-container">
    <div class="my-point-group">
      <div class="paid-year">2023</div>
      <div class="my-point-card-group">
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-gain-date">2023-07-07</div>
            <div class="card-limit-date">使用期限 2024-07-07</div>
          </div>
          <div class="card-gained-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">+20</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="canUse == 1" class="my-point-container">
    <div class="my-point-group">
      <div class="paid-year">2023</div>
      <div class="my-point-card-group">
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-gain-date">2023-07-07</div>
            <div class="card-used-date">使用日期 2024-07-07</div>
          </div>
          <div class="card-used-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-20</div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-point-group">
      <div class="paid-year">2022</div>
      <div class="my-point-card-group">
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20220707001</div>
            <div class="card-gain-date">2022-07-07</div>
            <div class="card-used-date">使用日期 2023-07-07</div>
          </div>
          <div class="card-used-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-20</div>
          </div>
        </div>
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20220707001</div>
            <div class="card-gain-date">2022-07-07</div>
            <div class="card-used-date">使用日期 2023-07-07</div>
          </div>
          <div class="card-used-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-100</div>
          </div>
        </div>
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20220707001</div>
            <div class="card-gain-date">2022-07-07</div>
            <div class="card-used-date">使用日期 2023-07-07</div>
          </div>
          <div class="card-used-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-20</div>
          </div>
        </div>
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20220707001</div>
            <div class="card-gain-date">2022-07-07</div>
            <div class="card-used-date">使用日期 2023-07-07</div>
          </div>
          <div class="card-used-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-20</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="canUse == 2" class="my-point-container">
    <div class="my-point-group">
      <div class="paid-year">2023</div>
      <div class="my-point-card-group">
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-point-status">點數失效</div>
            <div class="card-invalid-date">失效日期 2024-07-07</div>
          </div>
          <div class="card-losed-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-20</div>
          </div>
        </div>
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-point-status">點數失效</div>
            <div class="card-invalid-date">失效日期 2024-07-07</div>
          </div>
          <div class="card-losed-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-20</div>
          </div>
        </div>
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-point-status">點數失效</div>
            <div class="card-invalid-date">失效日期 2024-07-07</div>
          </div>
          <div class="card-losed-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-20</div>
          </div>
        </div>
        <div class="my-point-card">
          <div class="my-point-card-content">
            <div class="card-number">20230707001</div>
            <div class="card-point-status">點數失效</div>
            <div class="card-invalid-date">失效日期 2024-07-07</div>
          </div>
          <div class="card-losed-point">
            <div class="card-point-icon">P</div>
            <div class="card-point-value">-20</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BeAboutToBeInvalid
    :isShowPointDetail="isShowPointDetail"
    @closePointDetailModal="closePointDetailModal"
  ></BeAboutToBeInvalid>
</template>

<script>
import BeAboutToBeInvalid from "@/components/shop/BeAboutToBeInvalid";

export default {
  data() {
    return {
      canUse: 0,
      isShowPointDetail: false,
    };
  },
  components: {
    BeAboutToBeInvalid,
  },
  methods: {
    changeTag(e) {
      const tag = e.currentTarget;
      const useTags = document.querySelectorAll(".use-tags");
      useTags.forEach((t) => {
        t.classList.remove("selected-use-tag");
      });
      tag.classList.add("selected-use-tag");

      if (tag.id == "isGained") {
        this.canUse = 0;
      } else if (tag.id == "isUsed") {
        this.canUse = 1;
      } else if (tag.id == "isInvalid") {
        this.canUse = 2;
      }
    },
    openPointDetailModal() {
      this.isShowPointDetail = true;
    },
    closePointDetailModal() {
      this.isShowPointDetail = false;
    },
  },
};
</script>
