<template>
  <div class="join-member-check-bg">
    <div v-if="status == 'checking'" class="join-member-info">
      <div class="container">
        <div class="modal-header">
          <div class="modal-title">確認會員資料</div>
          <div class="close-icon" @click="onClosed">
            <font-awesome-icon :icon="['fas', 'xmark']" />
          </div>
        </div>
        <div class="form-content">
          <div class="customer-name customer-question">
            <label class="standard-label label-blue" for="name">姓名</label>
            <div class="standard-text" name="name">{{ form.CustomerName }}</div>
          </div>
          <div class="customer-gender customer-question">
            <label class="standard-label label-blue" for="gender">性別</label>
            <div class="standard-text" name="gender">{{ form.Gender }}</div>
          </div>
          <div class="customer-birthday customer-question">
            <label class="standard-label label-blue" for="birthday">生日</label>
            <div class="standard-text" name="birthday">
              {{ form.Birth }}
            </div>
          </div>
          <div class="customer-phone customer-question">
            <label class="standard-label label-blue" for="phone">電話</label>
            <div class="standard-text" name="phone">{{ form.Cellphone }}</div>
          </div>
          <div class="customer-email customer-question">
            <label class="standard-label label-blue" for="email">Email</label>
            <div class="standard-text" name="email">{{ form.Email }}</div>
          </div>
          <div class="customer-address customer-question">
            <label class="standard-label label-blue" for="address">地址</label>
            <div class="standard-text" name="address">
              <span>{{ form.Address }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer checking">
          <button class="cancel-btn" type="button" @click="onClosed">
            取消
          </button>
          <button class="submit-btn" type="button" @click="onSaved">
            確定
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="status == 'finishing'" class="join-member-finish">
      <div class="container">
        <div class="form-content-finish">
          <div class="finish-message">您已成功加入會員 !</div>
        </div>
        <div class="modal-footer-finish">
          <button class="ok-btn" type="button" @click="onFinished">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      form: {
        CustomerName: "",
        CustomerNumber: "",
        Gender: "",
        Cellphone: "",
        Email: "",
        Birth: "",
        Address: "",
      },
      status: "checking",
    };
  },
  props: {
    isShowModal: Boolean,
    item: Object,
  },
  watch: {
    isShowModal(value) {
      let bg = document.querySelector(".join-member-check-bg");
      if (value) {
        this.form.CustomerName = this.item.name;
        this.form.CustomerNumber = this.item.phone;
        this.form.Gender = this.item.gender;
        this.form.Cellphone = this.item.phone;
        this.form.Email = this.item.email;
        this.form.Birth = `${
          this.item.birthYear
        }-${this.item.birthMonth.padStart(
          2,
          "0"
        )}-${this.item.birthDay.padStart(2, "0")}`;

        if (this.item.country == "0") {
          this.form.Address = `(台灣)`;
        } else if (this.item.country == "1" && this.item.elseCountry) {
          this.form.Address = `(${this.item.elseCountry})`;
        }

        if (this.item.address) {
          this.form.Address = this.form.Address + this.item.address;
        }
        this.form.PickupAddress = this.item.pickupAddress;

        document.body.classList.add("modal-open");
        bg.classList.add("show-modal");
      } else {
        bg.classList.add("hide-modal");
        setTimeout(() => {
          document.body.classList.remove("modal-open");
          bg.classList.remove("show-modal");
          bg.classList.remove("hide-modal");
          this.status = "checking";
        }, 150);
      }
    },
  },
  methods: {
    onSaved() {
      const vm = this;
      this.$api.customers
        .save(this.form)
        .then(() => {
          localStorage.removeItem("item");
          vm.status = "finishing";
          vm.$emit("onSaved");
          vm.setCloseTimeout();
        })
        .catch((error) => {
          vm.$swal.fire({
            icon: "error",
            title: error.data.Message,
            showConfirmButton: true,
          });
        });
    },
    onClosed() {
      this.$emit("onClosed");
    },
    onFinished() {
      clearTimeout(this.setCloseTimeout());
      this.onClosed();
    },
    setCloseTimeout() {
      setTimeout(() => {
        this.onClosed();
      }, 60000);
    },
  },
};
</script>
