import { httpPost } from "@/request/http";

let path = "phone-verify";
const url = process.env.VUE_APP_API_URL;

export default {
  checkVerify(item) {
    return httpPost(`${url}/${path}`, item);
  },
  register(item) {
    return httpPost(`${url}/register/${path}`, item);
  },
  forgetPwd(item) {
    return httpPost(`${url}/forget-password/${path}`, item);
  },
};
