<template>
  <div class="point-modal">
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 160px;
        color: white;
        background-color: #aaa;
      "
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        "
      >
        <div
          style="position: absolute; left: 20px; font-weight: 100"
          @click="closePointDetailModal"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 50px;
              font-size: 24px;
            "
          >
            <font-awesome-icon :icon="['fas', 'angle-left']" />
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 300;
              padding-bottom: 5px;
            "
          >
            店家名稱
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 24px;
            "
          >
            <div>點數詳情</div>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                margin-top: 3px;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="box-sizing: border-box; width: 100%; margin-bottom: 80px">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0 15px 0;
        "
      >
        <div
          style="
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 140px;
            height: 78px;
            border: 2px solid white;
            border-radius: 8px;
            background-color: white;
          "
          @click="openPointDetailModal"
        >
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;
                border-radius: 14px;
                background-color: #3e5ba9;
                color: white;
                font-size: 18px;
              "
            >
              P
            </div>
            <div
              style="
                margin: 0 0 3px 5px;
                color: black;
                font-size: 24px;
                font-weight: 500;
              "
            >
              10
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              color: #3e5ba9;
            "
          >
            <div>30天內失效</div>
            <div>
              <font-awesome-icon :icon="['fas', 'angle-right']" />
            </div>
          </div>
        </div>
      </div>
      <div class="my-point-container">
        <div class="my-point-group">
          <div class="paid-year">即將失效</div>
          <div class="my-point-card-group">
            <div class="my-point-card">
              <div class="my-point-card-content">
                <div class="card-number">20230707001</div>
                <div class="card-gain-date">2023-07-07</div>
                <div class="card-limit-date">使用期限 2024-07-07</div>
              </div>
              <div class="card-gained-point">
                <div class="card-point-icon">P</div>
                <div class="card-point-value">20</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["isShowPointDetail"],
  watch: {
    isShowPointDetail(value) {
      const modal = document.querySelector(".point-modal");
      if (value) {
        document.body.classList.add("modal-open");
        modal.classList.add("show-detail-modal");
      } else {
        modal.classList.add("hide-detail-modal");
        setTimeout(() => {
          document.body.classList.remove("modal-open");
          modal.classList.remove("show-detail-modal");
          modal.classList.remove("hide-detail-modal");
        }, 150);
      }
    },
  },
  methods: {
    clickTag(e) {
      const div = e.currentTarget;
      const selectedTag = document.querySelector(".selected-tag");
      const pageTags = document.querySelectorAll(".page-tags");
      if (selectedTag) {
        selectedTag.remove();
        pageTags.forEach((t) => {
          t.classList.remove("color-white");
        });
      }

      let tag = document.createElement("div");
      tag.classList.add("selected-tag");
      div.appendChild(tag);
      div.classList.add("color-white");

      this.componentId = div.id;
    },
    closePointDetailModal() {
      this.$emit("closePointDetailModal");
    },
  },
  mounted() {
    if (this.$route.params.id) {
      localStorage.setItem("shopID", this.$route.params.id);
    }
    if (!this.componentId) {
      this.componentId = "ShopIntroduce";
    }
  },
};
</script>
