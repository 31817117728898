<template>
  <div id="third-step">
    <div style="padding: 50px 0 10px 0; font-size: 24px; font-weight: 500">
      設定密碼
    </div>
    <div>
      <div class="old-password-input">
        <label class="set-pwd-label label-blue" for="oldPwd">輸入舊密碼</label>
        <input
          id="oldPwd"
          class="set-pwd-input"
          type="password"
          v-model="oldPassword"
          required
        />
        <div class="set-pwd-switch" @click="pwdSwitcher('oldPwd')">
          <font-awesome-icon :icon="['fas', 'eye']" />
        </div>
      </div>
      <div class="set-password-input">
        <label class="set-pwd-label label-blue" for="newPwd">輸入新密碼</label>
        <input
          id="newPwd"
          class="set-pwd-input"
          type="password"
          v-model="newPassword"
          required
        />
        <div class="set-pwd-switch" @click="pwdSwitcher('newPwd')">
          <font-awesome-icon :icon="['fas', 'eye']" />
        </div>
      </div>
      <div class="set-password-input">
        <label class="set-pwd-label label-blue" for="repeatNewPwd"
          >確認新密碼</label
        >
        <input
          id="repeatPwd"
          class="set-pwd-input"
          type="password"
          v-model="repeatNewPassword"
          required
        />
        <div class="set-pwd-switch" @click="pwdSwitcher('repeatPwd')">
          <font-awesome-icon :icon="['fas', 'eye']" />
        </div>
      </div>
    </div>

    <div
      v-if="pwdAllFilled"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        padding: 30px 0 20px 0;
      "
    >
      <button
        class="submit-btn"
        style="padding-bottom: 3px"
        type="button"
        @click="checkPassword"
      >
        確定
      </button>
    </div>
    <div
      v-else
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        padding: 30px 0 20px 0;
      "
    >
      <button
        style="
          padding-bottom: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background-color: #3e5ba991;
          margin: 0 10px;
          width: 160px;
          height: 44px;
          border: 0;
          border-radius: 8px;
          font-size: 20px;
        "
        type="button"
        disabled
      >
        確定
      </button>
    </div>
    <div style="color: #3e5ba9; margin-top: -5px">
      <span style="cursor: pointer" @click="backToLogin">取消</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    };
  },
  computed: {
    pwdAllFilled() {
      return this.oldPassword && this.newPassword && this.repeatNewPassword;
    },
  },
  methods: {
    checkPassword() {
      if (this.newPassword != this.repeatNewPassword) {
        this.$swal.fire({
          icon: "error",
          title: "輸入密碼不一致",
          showConfirmButton: true,
        });
        return;
      }
      this.$api.members
        .changePwd({
          OldPassword: this.$func.crypto.encrypt(this.oldPassword),
          NewPassword: this.$func.crypto.encrypt(this.newPassword),
        })
        .then(() => {
          this.$swal.fire({
            icon: "success",
            title: "密碼變更成功，請重新登入 !",
            showConfirmButton: true,
          });
          localStorage.removeItem("token");
          this.$router.push({
            name: "Login",
          });
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.data.Message,
            showConfirmButton: true,
          });
        });
    },
    pwdSwitcher(id) {
      const pwdInput = document.getElementById(id);
      if (pwdInput.type == "password") {
        pwdInput.type = "text";
      } else {
        pwdInput.type = "password";
      }
    },
    backToLogin() {
      window.history.back();
    },
  },
};
</script>
